<template>
    <v-app style="background-color: #f2f2f2">
      <v-layout row wrap align-center justify-center>
        <v-flex lg4 md5 sm7 xs7 class="text-center">
          <img src="@/assets/logo_onebox-horizontal-png.png" width="45%" height="45%" alt="OneBox" />
          <br />
          <br />
          <v-progress-linear
            v-if="fail_ === false"
            background-color="light-blue lighten-4"
            color="success"
            indeterminate
            rounded
          ></v-progress-linear>
          <br />
          <p v-if="fail_ === false" class="text-center">รอสักครู่</p>
          <p v-else class="text-center"><v-icon color="error">error</v-icon>&nbsp;{{errormessage}}</p>
        </v-flex>
      </v-layout>
    </v-app>
  </template>
  <script>
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import "sweetalert2/src/sweetalert2.scss";
  import { mapState, mapGetters } from "vuex";
  import gbfGenerate from "@/globalFunctions/generateAuthorize";
  import VueCookies from "vue-cookies";
  
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });
  
  export default {
    data: function() {
      return {
        fail_: false,
        errormessage: "",
      };
    },
    created() {},
    computed: {
      ...mapState(["username", "authorize", "account_active", "color", "role_level"]),
      ...mapState({ processloader: "loading" }),
      ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
        "dataLoginDefault",
      ]),
    },
    methods: {
      reset_login() {
        this.$store
          .dispatch("authorize_expired")
          .then(this.login())
          .catch((error) => {
            console.log(error);
          });
      },
      async login() {
        //เช็คว่ามี accesstoken มั้ย
        if (!this.$route.query.sharetoken) {
          this.fail_ = true;
          this.errormessage = "Accesss Denied !!"        
        } else {
          let _accesstoken = this.$route.query.sharetoken;
  
          let payload = {
            accesstoken: _accesstoken,
          };
  
          //localStorage.clear();
          let encodetoken = window.btoa(_accesstoken)
          sessionStorage.setItem("checklogin", false);

          sessionStorage.setItem("token", encodetoken);
          sessionStorage.setItem("token_auth", true);
          //localStorage.setItem("token", encodetoken);

          VueCookies.set('token', encodetoken);
          VueCookies.set('token_auth', true);
  
          // 1 ยิง API getprofile
          let check_auth = await this.$store.dispatch("authorize_checking", payload);
          if (check_auth.status === "Access Granted") {
            VueCookies.set('ggdrive', true);
            this.$router.push({ path: "/mydrive" });
          } else {
            this.fail_ = true;
            this.errormessage = "ไม่สามารถ login ได้ !!"
          }
        }
      }
    },
    mounted() {
      console.log(",kkkkkkkkk", this.$route.query);
      this.reset_login();
    }
  }
  </script>
  